<template>
<div class="ui-multiselect-wrapper">
  <ValidationProvider tag="div" :detect-input="detectInput" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <UiFormItem :title="title" :instructions="instructions" :required="required" :error-msg="errors[0]" :error="!!errors[0]" :root="true">
      <multiselect class="ui-multiselect" v-bind="$attrs" :placeholder="$attrs.placeholder || $t('Select option')" v-on="$listeners" :class="{ '-error': !!errors[0] }">
        <UIIcon class="caret" name="chevron-down" slot="caret"></UIIcon>
          <template #option="props">
            <slot name="option" v-bind="props"></slot>
          </template>
          <template #noResult="">
            <slot name="noResult"></slot>
          </template>
      </multiselect>
      <template #instructions="" v-if="$scopedSlots.instructions">
        <slot name="instructions"></slot>
      </template>
    </UiFormItem>
  </ValidationProvider>
</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import UiFormItem from './UIFormItem';
import UIIcon from './UIIcon';
import Multiselect from 'vue-multiselect';

@Component({
  props: {
    ...UiFormItem.options.props,
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined,
    },
    detectInput: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ValidationProvider,
    UiFormItem,
    UIIcon,
    Multiselect,
  },
  inheritAttrs: false,
})
export default class UIMultiselect extends Vue {
}
</script>

<style lang="postcss">
@import "./vue-multiselect";
</style>
<style scoped lang="postcss">
@import "../../styles";

.ui-multiselect {
  font-family: var(--fontSansSerif);

  & .caret {
    margin: 1.5rem;
    position: absolute;
    right: 0;
    fill: var(--colorManatee);
  }

  &.-error {
    & >>> .multiselect__tags {
      border-color: var(--colorErrorFlat);
      box-shadow: none;
      border-width: 2px;
    }
  }

  & >>> .multiselect__tags {
    @apply --text;
    min-height: 48px;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
  }
  & >>> .multiselect__tags-wrap {
    line-height: 2;
  }
  & >>> .multiselect__placeholder,
  & >>> .multiselect__input,
  & >>> .multiselect__single {
    line-height: 1.8;
  }
  & >>> .multiselect__tag {
    vertical-align: middle;
    /* margin-bottom: 0; */
    /* padding-top: 2px; */
    /* padding-bottom: 2px; */
  }
}
</style>
